import { useContext } from 'react'

import { Box, Typography } from '@mui/material'

import { useQuery } from '@redwoodjs/web'

import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'

import SidebarActions from '../SidebarActions/SidebarActions'

const GET_ACTIONS_FOR_ORGANIZATION_SIDEBAR = gql`
  query GetActionsForOpportunitySidebar(
    $opportunityId: String!
    $workspaceId: String!
  ) {
    actionsForOpportunity(
      opportunityId: $opportunityId
      workspaceId: $workspaceId
    ) {
      id
      title
      status {
        id
        label
        updatedAt
      }
      owner {
        id
        email
      }
      channel {
        id
        label
        accountId
        type
      }
      createdAt
    }
  }
`

const OpportunityActions = ({ opportunityId }: { opportunityId: string }) => {
  const { selectedWorkspace } = useContext(DayContext)
  const { data, refetch, loading } = useQuery(
    GET_ACTIONS_FOR_ORGANIZATION_SIDEBAR,
    {
      variables: { opportunityId, workspaceId: selectedWorkspace },
      skip: !selectedWorkspace,
      onCompleted: (data) => {
        logger.dev({ data })
      },
    }
  )
  const actions = data?.actionsForOpportunity || []
  return actions.length > 0 ? (
    <Box sx={{ mt: 0 }}>
      <Typography variant="h2">Actions</Typography>
      <SidebarActions
        actions={actions}
        onUpdate={refetch}
      />
    </Box>
  ) : null
}

export default OpportunityActions
