import { logger } from './logger'

export const internalUserEmails = [
  'markitecht@gmail.com',
  'erik@ipsumcreative.com',
  'gwendolynr@gmail.com',
  'erin@day.ai',
  'michael.g.pici@gmail.com',
  'asifreza490@gmail.com',
  'rezaasif.me@gmail.com',
  'sohaib@enrichverx.com',
  'christopher@theproviders.com',
]

export const isInternalUser = (user) => {
  return internalUserEmails.includes(user.email)
}

export const gatedForWorkAccountRemediationBanner = ['michael.g.pici@gmail.com']

export const ungatedForSearch = [
  'michael.g.pici@gmail.com',
  'markitecht@gmail.com',
  'erik@ipsumcreative.com',
]

export const ungatedForMeetingRecordingCache = []

export const ungatedForActions = (user) => {
  return [
    'markitecht@gmail.com',
    'michael.g.pici@gmail.com',
    'erik@ipsumcreative.com',
    'gwendolynr@gmail.com',
    'erin@day.ai',
  ].includes(user.email)
}

export const ungatedForMarketing = (user) => {
  return ['markitecht@gmail.com'].includes(user.email)
}

export const ungatedForCustomers = (user) => {
  return ['markitecht@gmail.com'].includes(user.email)
}

export const ungatedForSupport = (user) => {
  return ['markitecht@gmail.com'].includes(user.email)
}

export const isObjPropOnly = (user) => {
  return ['markitecht@gmail.com'].includes(user.email)
}

export const coreContactAdmins = ['markitecht@gmail.com', 'erin@day.ai']

export const isCoreContactAdmin = (user) => {
  return coreContactAdmins.includes(user.email)
}

export const ungatedForCrm3 = ({ selectedWorkspace, workAccounts }) => {
  if (!selectedWorkspace) {
    return false
  }

  const localStorageKey = `ungatedForCrm3_${selectedWorkspace}`
  const localStorageValue = localStorage.getItem(localStorageKey)

  if (localStorageValue && localStorageValue === 'true') {
    return true
  }

  for (const workAccount of workAccounts || []) {
    if (workAccount.crm3WorkspaceId === selectedWorkspace) {
      localStorage.setItem(localStorageKey, 'true')
      return true
    }
  }

  return false
}

export const ungatedForCrm3WorkAccount = ({
  selectedWorkspace,
  workAccounts,
}) => {
  for (const workAccount of workAccounts || []) {
    logger.dev({ workAccount })
    if (workAccount.crm3WorkspaceId === selectedWorkspace) {
      return workAccount.email
    }
  }

  return null
}
