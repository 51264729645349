import { useContext } from 'react'

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

import { useQuery } from '@redwoodjs/web'

import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'

const GET_ORGANIZATION_FOR_OPPORTUNITY = gql`
  query GetOrganizationForOpportunity($id: String!, $workspaceId: String!) {
    organization(id: $id, workspaceId: $workspaceId) {
      id
      overview
    }
  }
`

const OrganizationChallenges = ({ orgId }: { orgId: string }) => {
  const { selectedWorkspace } = useContext(DayContext)
  const { data, loading } = useQuery(GET_ORGANIZATION_FOR_OPPORTUNITY, {
    variables: { id: orgId, workspaceId: selectedWorkspace },
    skip: !selectedWorkspace || !orgId,
    onCompleted: ({ organization }) => {
      logger.dev({ organization })
    },
  })

  const org = data?.organization
  return (
    org?.overview?.['objective/challengesAndSolutions']?.length > 0 && (
      <>
        <Typography variant="h2">Challenges & Solutions</Typography>
        <Box
          sx={{
            mt: 1,
            mb: 5,
            border: (theme) => `1px solid ${theme.palette.divider}`,
            borderRadius: '8px',
            background: (theme) => theme.palette.background.paper,
            p: 1,
          }}
        >
          <TableContainer>
            <Table
              sx={{
                '& .MuiTableCell-head': {
                  fontSize: '12px',
                  fontWeight: 600,
                  pt: '4px',
                  pb: '6px',
                },
                '& .MuiTableCell-root': {
                  border: 'none',
                },
                '& .MuiTableBody-root .MuiTableRow-root': {
                  borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                  '&:last-child': {
                    borderBottom: 'none',
                  },
                },
                '& .MuiTableHead-root .MuiTableRow-root': {
                  borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Challenge</TableCell>
                  <TableCell>Proposed Solution</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {org?.overview?.['objective/challengesAndSolutions']?.map(
                  (item, index) => (
                    <TableRow key={`challenge${index}`}>
                      <TableCell
                        sx={{
                          fontWeight: 500,
                          fontSize: '0.8rem',
                          verticalAlign: 'top',
                        }}
                      >
                        {item.challenge}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontStyle: 'italic',
                          fontSize: '0.8rem',
                          verticalAlign: 'top',
                        }}
                      >
                        {item.solution}
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </>
    )
  )
}

export default OrganizationChallenges
