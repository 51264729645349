import { useContext, useState } from 'react'

import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {
  RiArrowLeftSLine,
  RiBarChart2Fill,
  RiBardLine,
  RiBuilding2Line,
  RiCloseLine,
  RiCurrencyLine,
  RiDeleteBinLine,
  RiPencilLine,
  RiStickyNoteAddLine,
} from '@remixicon/react'
import dayjs from 'dayjs'
import { useConfirm } from 'material-ui-confirm'
import toast from 'react-hot-toast'
import { getDomain } from 'tldts'

import { useMutation, useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import ContactTile from 'src/components/ContactTile/ContactTile'
import OpportunityActions from 'src/components/OpportunityActions/OpportunityActions'
import OrganizationPageCreateInstructions from 'src/components/OrganizationPageCreateInstructions/OrganizationPageCreateInstructions'
import OrganizationChallenges from 'src/components/Organizations/OrganizationChallenges/OrganizationChallenges'
import OpportunityRolesList from 'src/components/Pipeline/OpportunityRolesList/OpportunityRolesList'
import Row from 'src/components/Row/Row'
import WorkspaceMemberSelect from 'src/components/WorkspaceMemberSelect/WorkspaceMemberSelect'
import WorkspaceUserContextDialog from 'src/components/WorkspaceUserContextDialog/WorkspaceUserContextDialog'
import { DayContext } from 'src/lib/dayContext'
import { isInternalUser } from 'src/lib/gates'
import { logger } from 'src/lib/logger'
import { NativeObjectTypes } from 'src/lib/objects'

import { actionChipStyle } from '../Sidebar'

/*
  input OpportunityUpdateInput {
    id: String!
    stageId: String
    position: Int
    title: String
    type: String
    ownerId: String
    ownerEmail: String
    expectedCloseDate: DateTime
    primaryPerson: String
    currentStatus: String
    expectedRevenue: Float
    domain: String
  }

  */

// mutation to updateOpportunity:
const UPDATE_OPPORTUNITY_FROM_SIDEBAR = gql`
  mutation sidebarUpdateOpportunity($input: OpportunityUpdateInput!) {
    updateOpportunity(input: $input) {
      id
    }
  }
`

const DELETE_OPPORTUNITY = gql`
  mutation sidebarDeleteOpportunity(
    $id: String!
    $workspaceId: String!
    $pipelineId: String!
  ) {
    deleteOpportunity(
      id: $id
      workspaceId: $workspaceId
      pipelineId: $pipelineId
    ) {
      id
      objectType
    }
  }
`

const GET_ORGANIZATION_FOR_OPP_SIDEBAR = gql`
  query SidebarGetOrganizationForOppCreateDialog(
    $workspaceId: String!
    $orgId: String!
  ) {
    organization(id: $orgId, workspaceId: $workspaceId) {
      name
      domain
      subCompanyTag
      description
      aiDescription
      promises
      naicsCodes
      sicCodes
      industry
      employeeCount
      annualRevenue
      funding
      address
      city
      state
      country
      postalCode
      colorVibrant
      colorDarkVibrant
      colorLightVibrant
      colorMuted
      colorDarkMuted
      colorLightMuted
      photoSquare
      photoIcon
      photoBanner
      stockTicker
      socialTwitter
      socialLinkedIn
      socialFacebook
      socialYouTube
      markdown
      createdAt
      updatedAt
      edgarCik
      crunchbaseEntityId
      similarDomains
      resolvedUrl
      relationshipTypes
      overview
    }
  }
`

const GET_CONTACT_BY_EMAIL_FOR_OPPORTUNITY_SIDEBAR = gql`
  query GetContactByEmailForOpportunitySidebar(
    $contactEmail: String!
    $ownerEmail: String!
  ) {
    getContactByEmail(contactEmail: $contactEmail, ownerEmail: $ownerEmail) {
      objectId
      objectType
      properties
    }
  }
`

const MODEL_OPPORTUNITY_FROM_SIDEBAR = gql`
  mutation ModelOpportunityFromSidebar($opportunityId: String!) {
    modelOpportunityAsync(opportunityId: $opportunityId)
  }
`

const SidebarLayoutOpportunity = ({ crmObject, setOpen }) => {
  logger.dev({ crmObject })
  const { currentUser: user } = useAuth()
  const { selectedWorkspace, setSidebarObject } = useContext(DayContext)
  const confirm = useConfirm()

  const [editing, setEditing] = useState(false)
  const [noteData, setNoteData] = useState({
    id: null,
    domain: null,
  })

  const passedOpportunity = {
    id: crmObject.objectId,
    title: crmObject.properties.title,
    primaryPerson: crmObject.properties.primaryPerson?.objectId,
    type: crmObject.properties.type,
    ownerEmail: crmObject.properties.ownerEmail,
    expectedCloseDate: crmObject.properties.expectedCloseDate,
    currentStatus: crmObject.properties.currentStatus,
    expectedRevenue: crmObject.properties.expectedRevenue,
    domain: crmObject.properties.domain,
    hasRevenue: crmObject.properties.hasRevenue,
    pipelineId: crmObject.properties.pipelineId,
    workspaceId: crmObject.properties.workspaceId,
    opportunityTypes: crmObject.properties.opportunityTypes,
    roles: crmObject.properties.roles,
    createdAt: crmObject.properties.createdAt,
    updatedAt: crmObject.properties.updatedAt,
    position: crmObject.properties.position,
    stageId: crmObject.properties.stageId,
  }

  const [opportunity, setOpportunity] = useState(passedOpportunity)
  const [updateOpportunity] = useMutation(UPDATE_OPPORTUNITY_FROM_SIDEBAR)
  const [deleteOpportunity] = useMutation(DELETE_OPPORTUNITY)
  const domain = opportunity?.domain
    ? getDomain(`http://${opportunity.domain}`)
    : null
  const { data: organizationData, loading: organizationLoading } = useQuery(
    GET_ORGANIZATION_FOR_OPP_SIDEBAR,
    {
      variables: {
        workspaceId: opportunity.workspaceId,
        orgId: domain,
      },
      skip: !domain || !opportunity?.workspaceId,
    }
  )

  const { data: contactData, loading: contactLoading } = useQuery(
    GET_CONTACT_BY_EMAIL_FOR_OPPORTUNITY_SIDEBAR,
    {
      variables: {
        contactEmail: opportunity.primaryPerson,
        ownerEmail: user?.email,
      },
      skip: !user || !opportunity?.primaryPerson,
    }
  )

  const contact = contactData?.getContactByEmail

  const organization = organizationData?.organization

  const closeAndRefetch = () => {
    if (crmObject?.properties?.refetch) crmObject?.properties?.refetch()
    setOpen(false)
  }

  const [modelOpportunity] = useMutation(MODEL_OPPORTUNITY_FROM_SIDEBAR)

  const handleModelOpportunity = async () => {
    await toast.promise(
      modelOpportunity({ variables: { opportunityId: opportunity.id } }),
      {
        loading: 'Modeling Opportunity requested...',
        success: () => {
          return 'Opportunity Modeling underway!'
        },
        error: 'Error Modeling Opportunity',
      }
    )
  }

  const handleUpdateOpportunity = async () => {
    const oppToSave = { ...opportunity }
    delete oppToSave.updatedAt
    delete oppToSave.createdAt
    delete oppToSave.opportunityTypes
    toast.promise(updateOpportunity({ variables: { input: oppToSave } }), {
      loading: 'Updating Opportunity...',
      success: () => {
        closeAndRefetch()
        return 'Opportunity Updated!'
      },
      error: 'Error Updating Opportunity',
    })
  }

  const handleDeleteOpportunity = async () => {
    try {
      await confirm({
        description: 'Are you sure you want to delete this opportunity?',
      })

      await toast.promise(
        deleteOpportunity({
          variables: {
            id: crmObject.objectId,
            workspaceId: crmObject.properties.workspaceId,
            pipelineId: crmObject.properties.pipelineId,
          },
        }),
        {
          loading: 'Deleting Opportunity...',
          success: () => {
            closeAndRefetch()
            return 'Opportunity Deleted!'
          },
          error: 'Error Deleting Opportunity',
        }
      )
    } catch (error) {
      console.error('Failed to delete the opportunity:', error)
    }
  }

  if (passedOpportunity.id !== opportunity.id) {
    setOpportunity(passedOpportunity)
  }

  return (
    <>
      <Row sx={{ justifyContent: 'space-between', pt: 2, pl: 3, pr: 1, pb: 1 }}>
        <Row
          gap={1}
          sx={{ width: '410px', overflow: 'hidden' }}
        >
          {organizationLoading ? (
            <CircularProgress
              color="secondary"
              size={24}
            />
          ) : (
            <RiBarChart2Fill
              size={24}
              color={organization?.colorVibrant}
            />
          )}
          <Typography
            variant="h2"
            sx={{
              width: '234px',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              flexShrink: 1,
            }}
          >
            {opportunity.title || 'Opportunity'}
          </Typography>
        </Row>
        <Row gap={1}>
          {isInternalUser(user) && (
            <Tooltip title="Model Opportunity (internal)">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation()
                  handleModelOpportunity()
                }}
              >
                <RiBardLine size={15} />
              </IconButton>
            </Tooltip>
          )}
          <Chip
            clickable={true}
            icon={<RiStickyNoteAddLine size={15} />}
            label="Note"
            variant="outlined"
            sx={actionChipStyle}
            onClick={(e) => {
              e.stopPropagation()
              setNoteData({ id: opportunity.id, domain: opportunity.domain })
            }}
          />
          <WorkspaceUserContextDialog
            title="Add Note"
            opportunityId={noteData.id}
            organizationId={noteData.domain}
            onClose={(e) => {
              e.stopPropagation()
              setNoteData({ id: null, domain: null })
              crmObject?.properties?.refetch()
            }}
          />
          {opportunity.domain && (
            <Chip
              icon={<RiBuilding2Line size={15} />}
              label="View Org"
              variant="outlined"
              sx={actionChipStyle}
              onClick={() => {
                setSidebarObject({
                  objectId: opportunity.domain,
                  objectType: NativeObjectTypes.Organization,
                  properties: { domain: opportunity.domain },
                })
              }}
            />
          )}
          {opportunity.domain && (
            <OrganizationPageCreateInstructions orgId={opportunity.domain} />
          )}
          <Chip
            icon={<RiPencilLine size={15} />}
            label="Edit"
            variant="outlined"
            sx={actionChipStyle}
            onClick={(e) => {
              e.stopPropagation()
              setEditing(true)
            }}
          />
          <IconButton
            onClick={(e) => {
              e.stopPropagation()
              setOpen(false)
            }}
          >
            <RiCloseLine />
          </IconButton>
        </Row>
      </Row>
      <Box>
        <Box>
          {!editing ? (
            <>
              <Row sx={{ justifyContent: 'space-between', mt: 3, p: 3, pt: 0 }}>
                <Row>
                  <Row gap={2}>
                    <Typography variant="h3">
                      {`${
                        opportunity.hasRevenue
                          ? '$' + opportunity.expectedRevenue + ', closing by '
                          : 'Close date: '
                      }  ${dayjs(opportunity.expectedCloseDate).format(
                        'MMM D, YYYY'
                      )}`}
                    </Typography>

                    <Chip
                      onClick={(e) => {
                        e.stopPropagation()
                        setEditing(true)
                      }}
                      label="Edit"
                      variant="outlined"
                      sx={actionChipStyle}
                    />
                  </Row>
                </Row>
              </Row>
              <Box sx={{ height: 'calc(100vh - 156px)', overflowY: 'auto' }}>
                <Box sx={{ px: 3 }}>
                  <Box sx={{}}>
                    <OpportunityRolesList
                      id={opportunity.id}
                      showHeader={false}
                    />
                  </Box>
                  <Box>
                    <OpportunityActions opportunityId={opportunity.id} />
                  </Box>
                  {opportunity.domain && (
                    <OrganizationChallenges orgId={opportunity.domain} />
                  )}
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Button
                onClick={() => setEditing(false)}
                color="primary"
                sx={{ mb: 3 }}
                disableElevation={true}
                startIcon={<RiArrowLeftSLine />}
              >
                Back to view opportunity
              </Button>
              <TextField
                label="Title"
                value={opportunity.title}
                fullWidth={true}
                onChange={(e) =>
                  setOpportunity({ ...opportunity, title: e.target.value })
                }
                sx={{ mb: 3 }}
              />

              {opportunity?.opportunityTypes ? (
                <Autocomplete
                  options={opportunity?.opportunityTypes}
                  value={opportunity.type}
                  freeSolo={true}
                  onChange={(e, newValue) =>
                    setOpportunity({ ...opportunity, type: newValue })
                  }
                  renderInput={(params) => (
                    <TextField
                      sx={{ mb: 3 }}
                      {...params}
                    />
                  )}
                />
              ) : (
                <TextField
                  label="Type"
                  value={opportunity.type}
                  fullWidth={true}
                  sx={{ mb: 3 }}
                />
              )}
              {opportunity.ownerEmail ? (
                <FormControl fullWidth={true}>
                  <InputLabel
                    sx={{ display: 'none' }}
                    id="owner"
                  >
                    {'Owner'}
                  </InputLabel>
                  <TextField
                    id="owner"
                    label="Owner"
                    fullWidth={true}
                    InputProps={{
                      sx: {
                        height: '52px',
                        width: '100%',
                        '& .MuiInputBase-input': {
                          display: 'none !important',
                        },
                      },
                      endAdornment: (
                        <IconButton
                          sx={{ borderRadius: '2px', p: '2px' }}
                          onClick={() =>
                            setOpportunity({
                              ...opportunity,
                              ownerEmail: '',
                            })
                          }
                        >
                          <RiCloseLine />
                        </IconButton>
                      ),
                      startAdornment: (
                        <Box
                          sx={{
                            width: '572px',
                            flexShrink: 0,
                          }}
                        >
                          <ContactTile
                            showSidebar={false}
                            email={opportunity.ownerEmail}
                          />
                        </Box>
                      ),
                    }}
                  />
                </FormControl>
              ) : (
                <WorkspaceMemberSelect
                  onSelect={(selected) => {
                    setOpportunity({
                      ...opportunity,
                      ownerEmail: selected[0].email,
                    })
                  }}
                  label={'Owner'}
                />
              )}
              <Row
                sx={{ my: 3 }}
                gap={2}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Expected Close Date"
                    value={dayjs(opportunity.expectedCloseDate)}
                    onChange={(e) => {
                      setOpportunity({
                        ...opportunity,
                        expectedCloseDate: e.toISOString(),
                      })
                    }}
                    sx={{ width: '256px', flexShrink: 1 }}
                  />
                </LocalizationProvider>

                {opportunity.hasRevenue ? (
                  <Row>
                    <TextField
                      id="expectedRevenue"
                      label="Expected Amount"
                      variant="outlined"
                      type="number"
                      fullWidth={true}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                        endAdornment: (
                          <Tooltip
                            title="Remove revenue from pipeline"
                            placement="top"
                            arrow={true}
                          >
                            <IconButton
                              sx={{ p: '4px', borderRadius: '2px', ml: 1 }}
                              onClick={() =>
                                confirm({
                                  description:
                                    'Are you sure you want to remove revenue from this Pipeline?.',
                                }).then(() => {
                                  setOpportunity({
                                    ...opportunity,
                                    hasRevenue: false,
                                  })
                                })
                              }
                            >
                              <RiCloseLine />
                            </IconButton>
                          </Tooltip>
                        ),
                      }}
                      value={
                        typeof opportunity.expectedRevenue === 'number'
                          ? opportunity.expectedRevenue
                          : 0
                      }
                      onChange={(e) => {
                        const expectedRevenue = parseFloat(e.target.value)
                        setOpportunity({
                          ...opportunity,
                          expectedRevenue,
                        })
                      }}
                    />
                  </Row>
                ) : (
                  <Tooltip
                    title="Add revenue to pipeline"
                    placement="top"
                    arrow={true}
                  >
                    <Button
                      variant="outlined"
                      onClick={() =>
                        confirm({
                          description:
                            'Adding revenue to this Opportunity will turn revenue reporting on for this pipeline.',
                        }).then(() => {
                          setOpportunity({ ...opportunity, hasRevenue: true })
                        })
                      }
                      fullWidth={true}
                    >
                      <RiCurrencyLine style={{ marginRight: '8px' }} />
                      Add revenue
                    </Button>
                  </Tooltip>
                )}
              </Row>
              <Row gap={2}>
                <Button
                  onClick={handleUpdateOpportunity}
                  color="secondary"
                  variant="outlined"
                  fullWidth={true}
                  sx={{ flexShrink: 1 }}
                  disableElevation
                >
                  Update Opportunity
                </Button>

                <Tooltip
                  title="Delete Opportunity"
                  placement="top"
                  arrow={true}
                >
                  <IconButton
                    onClick={handleDeleteOpportunity}
                    sx={{ p: '4px', borderRadius: '2px' }}
                  >
                    <RiDeleteBinLine />
                  </IconButton>
                </Tooltip>
              </Row>
            </>
          )}
        </Box>
      </Box>
    </>
  )
}

export default SidebarLayoutOpportunity
