import { useContext, useState } from 'react'

import {
  Box,
  Button,
  Chip,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  RiCloseLine,
  RiShareForwardLine,
  RiStickyNoteAddLine,
} from '@remixicon/react'
import toast from 'react-hot-toast'
import { v4 as uuid } from 'uuid'

import { navigate, routes } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import DomainAvatar from 'src/components/DomainAvatar/DomainAvatar'
import OrganizationActions from 'src/components/OrganizationActions/OrganizationActions'
import OrganizationPageCreateInstructions from 'src/components/OrganizationPageCreateInstructions/OrganizationPageCreateInstructions'
import OrganizationPeople from 'src/components/OrganizationPeople/OrganizationPeople'
import OrganizationOneSentence from 'src/components/Organizations/OrganizationOneSentence/OrganizationOneSentence'
import OrganizationQuotes from 'src/components/Organizations/OrganizationQuotes/OrganizationQuotes'
import OrganizationSocialLinks from 'src/components/Organizations/OrganizationSocialLinks/OrganizationSocialLinks'
import OrganizationSummary from 'src/components/Organizations/OrganizationSummary/OrganizationSummary'
import OrganizationTile from 'src/components/Organizations/OrganizationTile/OrganizationTile'
import OrganizationTimeline from 'src/components/Organizations/OrganizationTimeline/OrganizationTimeline'
import OpportunityDomainChip from 'src/components/Pipeline/OpportunityDomainChip/OpportunityDomainChip'
import SlackChannels from 'src/components/SlackChannels/SlackChannels'
import SlackLogo from 'src/components/SlackLogo/SlackLogo'
import WorkspaceUserContextDialog from 'src/components/WorkspaceUserContextDialog/WorkspaceUserContextDialog'
import { DayContext } from 'src/lib/dayContext'
import { ungatedForCrm3 } from 'src/lib/gates'
import { logger } from 'src/lib/logger'

import Row from '../../Row/Row'
import { actionChipStyle, sidebarBodyTextStyle } from '../Sidebar'

const GET_ORGANIZATION_FOR_ORG_SIDEBAR = gql`
  query GetOrganizationForOrgSidebar($workspaceId: String!, $orgId: String!) {
    workspaceOrganization(workspaceId: $workspaceId, domain: $orgId) {
      id
      name
      domain
      description
      aiDescription
      people {
        fullName
        email
        currentJobTitle
        photoUrl
      }
      roles {
        name
        email
        role
      }
      opportunities {
        id
        workspaceId
        title
        pipelineId
        pipelineTitle
        stage
        updatedAt
      }
      pages {
        id
        title
      }
      photos {
        square
      }
      relationship {
        highLevelSummary
        upcomingEvents
        quotes {
          personEmail
          text
          meetingId
        }
        oneSentenceSummary
        proofOfPayment
        sensitiveToWhom
        sensitiveReasoning
        warmth
        origin
      }
      links {
        facebook
        x
        instagram
        linkedIn
        website
        websiteResolvedUrl
      }
    }
  }
`

const GET_SLACK_CHANNELS = gql`
  query GetSlackChannels($workspaceId: String!, $orgId: String!) {
    slackChannels(workspaceId: $workspaceId, orgId: $orgId) {
      id
      name
      summary
      slackChannelId
      slackIntegration {
        slackTeamId
      }
    }
  }
`

const SidebarLayoutOrganization = ({ domain, setOpen }) => {
  const { selectedWorkspace, workAccounts, workspacePeople } =
    useContext(DayContext)

  const [noteCreateOrganizationId, setNoteCreateOrganizationId] = useState(null)
  const [forceRefreshKey, setForceRefreshKey] = useState(uuid())

  const { data: orgData, loading: organizationLoading } = useQuery(
    GET_ORGANIZATION_FOR_ORG_SIDEBAR,
    {
      variables: {
        workspaceId: selectedWorkspace,
        orgId: domain,
      },
      skip: !domain,
      onCompleted: ({ workspaceOrganization }) => {
        logger.dev({ workspaceOrganization })
      },
    }
  )

  const { data: slackChannelsData } = useQuery(GET_SLACK_CHANNELS, {
    variables: {
      workspaceId: selectedWorkspace,
      orgId: domain,
    },
    skip: !domain || !selectedWorkspace,
    onCompleted: ({ slackChannels }) => {
      logger.dev({ slackChannels })
    },
  })
  const slackChannels = slackChannelsData?.slackChannels

  const organization = orgData?.workspaceOrganization

  return (
    <>
      <Row sx={{ justifyContent: 'space-between', pt: 2, px: 3, pb: '4px' }}>
        <Row gap={2}>
          <DomainAvatar
            domain={domain}
            size={52}
          />
          <Box sx={{ width: '410px', overflow: 'hidden' }}>
            <Row gap={1}>
              <Typography
                variant="h1"
                sx={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  flexShrink: 1,
                  fontSize: '1.5rem',
                }}
              >
                {organization?.name || organization?.domain}
              </Typography>
              <OrganizationSocialLinks organization={organization} />
            </Row>
            <Typography>{organization?.domain}</Typography>
          </Box>
        </Row>
        <Row gap={1}>
          <Button
            color="primary"
            variant="outlined"
            sx={{ px: 1, fontSize: '0.7rem', fontWeight: 500 }}
            onClick={() => {
              navigate(
                routes.objectDetail({
                  workspaceId: selectedWorkspace,
                  objectTypeSlug: 'organizations',
                  objectId: organization?.domain,
                })
              )
              setOpen(false)
            }}
          >
            View more
          </Button>
          <IconButton onClick={() => setOpen(false)}>
            <RiCloseLine />
          </IconButton>
        </Row>
      </Row>

      <Row
        sx={{ mt: 1, px: 3 }}
        gap={1}
      >
        <Tooltip title={`Add note to ${organization?.name}`}>
          <Chip
            clickable={true}
            icon={<RiStickyNoteAddLine size={14} />}
            label="Note"
            variant="outlined"
            size="small"
            sx={{ ...actionChipStyle }}
            onClick={() => {
              setNoteCreateOrganizationId(organization?.domain)
            }}
          />
        </Tooltip>
        {noteCreateOrganizationId && (
          <WorkspaceUserContextDialog
            onClose={() => {
              setNoteCreateOrganizationId(null)

              setTimeout(() => {
                setForceRefreshKey(uuid())
              }, 1000)
            }}
            title={`Add note to ${organization?.name}`}
            organizationId={noteCreateOrganizationId}
          />
        )}
        <Tooltip
          title="Copy link to clipboard"
          arrow={true}
        >
          <Chip
            clickable={true}
            onClick={() => {
              navigator.clipboard.writeText(
                `${process.env.HOST}${routes.objectDetail({
                  workspaceId: selectedWorkspace,
                  objectTypeSlug: 'organizations',
                  objectId: organization?.domain,
                })}`
              )
              toast.success('Link copied to clipboard!')
            }}
            size="small"
            sx={{ ...actionChipStyle }}
            label="Share"
            variant="outlined"
            icon={<RiShareForwardLine size={14} />}
          />
        </Tooltip>
        {ungatedForCrm3({
          selectedWorkspace,
          workAccounts,
        }) && <OrganizationPageCreateInstructions orgId={domain} />}
      </Row>
      <Box sx={{ px: 3, height: 'calc(100vh - 128px)', overflowY: 'auto' }}>
        <Box sx={{ pt: 2, pb: 3 }}>
          <Typography sx={sidebarBodyTextStyle}>
            {organization?.aiDescription || organization?.description}
          </Typography>
        </Box>
        <Box
          sx={{
            pt: 2,
            mb: 5,
          }}
        >
          <Row sx={{ justifyContent: 'space-between' }}>
            <Typography variant="h2">Opportunities</Typography>
            <OpportunityDomainChip
              organization={organization}
              openSidebar={true}
            />
          </Row>
        </Box>
        <Box>
          {organization?.relationship?.oneSentenceSummary && (
            <Box sx={{}}>
              <OrganizationOneSentence org={organization} />
            </Box>
          )}
          {organization?.relationship?.quotes && (
            <Box sx={{ mt: 3 }}>
              <OrganizationQuotes org={organization} />
            </Box>
          )}
          <Box sx={{ mt: 3 }}>
            <OrganizationActions orgId={organization?.domain} />
          </Box>
          {organization?.roles?.length > 0 && (
            <Box sx={{ mt: 3 }}>
              <OrganizationPeople organization={organization} />
            </Box>
          )}
          {slackChannels && slackChannels?.length > 0 && (
            <Box sx={{ mb: 2 }}>
              <Row sx={{ justifyContent: 'space-between' }}>
                <Typography variant="h2">Slack</Typography>

                {slackChannels?.length === 1 ? (
                  <Chip
                    variant="filled"
                    key={slackChannels?.[0].id}
                    icon={
                      <Row sx={{ justifyContent: 'right', width: '20px' }}>
                        <SlackLogo size={14} />
                      </Row>
                    }
                    label={`#${slackChannels?.[0].name}`}
                    onClick={() => {
                      const url = `slack://channel?id=${slackChannels?.[0].slackChannelId}&team=${slackChannels?.[0]?.slackIntegration?.slackTeamId}`
                      window.open(url, '_blank')
                    }}
                    sx={{
                      background: '#703259',
                      color: 'white',
                      fontWeight: '600',
                      fontSize: '12px',
                      letterSpacing: '-0.2px',
                    }}
                  />
                ) : null}
              </Row>
              <SlackChannels
                channels={slackChannels}
                includeTitle={false}
              />
            </Box>
          )}
          {organization?.relationship?.highLevelSummary && (
            <Box sx={{ mt: 3 }}>
              <OrganizationSummary org={organization} />
            </Box>
          )}
          {organization?.quotes?.length > 0 && (
            <Box sx={{ mt: 3 }}>
              <OrganizationQuotes org={organization} />
            </Box>
          )}
          {false && (
            <Box sx={{ mt: 3, mb: 6 }}>
              <Typography variant="h2">About</Typography>
              <Box
                sx={{ cursor: 'pointer', mt: 3 }}
                onClick={() => {
                  navigate(
                    routes.objectDetail({
                      objectId: organization?.domain,
                      workspaceId: selectedWorkspace,
                      objectTypeSlug: 'organizations',
                    })
                  )
                }}
              >
                <OrganizationTile
                  domain={organization?.domain}
                  openSidebar={false}
                />
              </Box>
            </Box>
          )}
          <Box sx={{ mt: 3 }}>
            <OrganizationTimeline
              org={organization}
              showHeader={true}
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default SidebarLayoutOrganization
