import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material'
import { RiCircleFill } from '@remixicon/react'
import { Organization } from 'types/graphql'

const OrganizationSummary = ({
  org,
  showTitle = true,
}: {
  org: Organization
  showTitle?: boolean
}) => {
  const theme = useTheme()
  return (
    org?.relationship?.highLevelSummary?.length > 0 && (
      <>
        {showTitle && <Typography variant="h2">Current Status</Typography>}
        <Box
          sx={{
            mt: 1,
            mb: 5,
            background: (theme) => theme.palette.background.paper,
          }}
        >
          <List
            sx={{ p: 0 }}
            disablePadding={true}
          >
            {org?.relationship?.highLevelSummary?.map((item, index) => (
              <ListItem
                key={`summaryItem_${index}`}
                sx={{
                  py: 1,
                  px: 0,
                  '&:last-child': {
                    borderBottom: 'none',
                  },
                }}
                disableGutters={true}
              >
                <ListItemIcon sx={{ width: '24px', minWidth: '24px' }}>
                  <RiCircleFill
                    size={10}
                    color={theme.palette.primary.main}
                  />
                </ListItemIcon>
                <ListItemText primary={item} />
              </ListItem>
            ))}
          </List>
        </Box>
      </>
    )
  )
}

export default OrganizationSummary
